import React from "react";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <motion.section
      id="contacto"
      className="py-12 bg-white"
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Contacto</h2>
        <form className="max-w-xl mx-auto">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Nombre</label>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-lg px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Correo</label>
            <input
              type="email"
              className="w-full border border-gray-300 rounded-lg px-4 py-2"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Mensaje</label>
            <textarea className="w-full border border-gray-300 rounded-lg px-4 py-2"></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 text-white rounded-lg px-4 py-2"
          >
            Enviar
          </button>
        </form>
      </div>
    </motion.section>
  );
};

export default Contact;
