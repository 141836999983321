import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Gallery from "./components/Gallery";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import ScrollToTopButton from "./components/ScrollToTopButton";
function App() {
  return (
    <div className="font-sans bg-gray-50">
      <Header />
      <Hero />
      <AboutUs />
      <Gallery />
      <Contact />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
