import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const images = [
    "https://live.staticflickr.com/65535/54170029956_114d2bfdfd_h.jpg",
    "https://live.staticflickr.com/65535/54170481740_abc0f22d1f_h.jpg",
    "https://live.staticflickr.com/65535/54170299063_d8ff443afd_h.jpg",
    "https://live.staticflickr.com/65535/54170481750_c293e04dbe_h.jpg",
    "https://live.staticflickr.com/65535/54169152507_7d25a226a2_h.jpg",
    "https://live.staticflickr.com/65535/54170331424_0cc08cb71b_h.jpg",
  ];

const Gallery = () => {
  return (
    <section id="galeria" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Galería</h2>
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          loop={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          spaceBetween={30}
          className="w-full max-w-5xl mx-auto"
        >
          {images.map((src, index) => (
            <SwiperSlide
              key={index}
              className="flex justify-center items-center"
              style={{ maxWidth: "80%" }}
            >
              <img
                src={src}
                alt={`Imagen ${index + 1}`}
                className="rounded-xl shadow-lg object-cover h-96 w-full"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Gallery;
